import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('views/home'),
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('views/user'),
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('views/other/record'),
    meta:{needLogin:true}
  },
  {
    path: '/examination',
    name: 'examination',
    component: () => import('views/examination'),
  }, 
  {
    path: '/examination/detail/:id',
    name: 'examinationDetail',
    component: () => import('views/examination/detail'),
    meta:{
      key:`/examination`
    }
  },
  {
    path: '/examination/applyform',
    name: 'examinationApplyForm',
    component: () => import('views/examination/examinationApplyForm'),
    meta:{
      key:`/examination`
    }
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('views/activity'),
  },
  {
    path: '/activity/detail/:id',
    name: 'activityDetail',
    component: () => import('views/activity/detail'),
    meta:{
      key:`/activity`
    }
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('views/company'),
  },
  {
    path: '/company/detail/:id',
    name: 'companyDetail',
    component: () => import('views/company/detail'),
    meta:{
      key:`/company`
    }
  },
  {
  path: '/resume',
  name: 'resume',
  component: () => import('views/personal')
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: () => import('views/jobs')
  },
  {
    path: '/specialCf',
    name: 'specialCf',
    component: () => import('views/specialCf')
  },
  {
    path: '/specialCf2',
    name: 'specialCf2',
    component: () => import('views/specialCf2')
  },
  {
    path: '/jobs/detail/:id',
    name: 'jobsDetail',
    component: () => import('views/jobs/detail'),
    meta:{
      key:`/jobs`
    }
  },
  {
    path: '/information',
    name: 'information',
    component: () => import('views/information')
  },
  {
    path: '/information/detail/:id',
    name: 'informationDetail',
    component: () => import('views/information/detail'),
    meta:{
      key:`/information`
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta:{
      key:`/information`
    }
  },
  {
    path: '/sixIndustry',
    name: 'sixIndustry',
    component: () => import('views/sixIndustry')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "test" */ '../views/test.vue'),
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "error_404" */ '../views/error_404.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log(from);
  next()
})

export default router
