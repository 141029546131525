<template>
  <div class="footer">
    <div class="page-container container">
      <div style="display:flex; justify-content: space-between;"> 
        <div class="footer-left">
          <div class="footer-left-links">
            <div class="footer-left-list">
              <p class="footer-left-list-title">网站服务</p>
              <p><a :href="links.ats" target="_blank">企业招聘服务</a></p>
              <p><a @click="changeShowLoginbox()">求职服务</a></p>
              <p><a :href="links.activity" target="_blank">招聘活动</a></p>
            </div>

            <div class="footer-left-list">
              <p class="footer-left-list-title">政策资讯</p>
              <p><a :href="links.information_0" target="_blank">公共服务</a></p>
              <p><a :href="links.information_1" target="_blank">国企招聘</a></p>
            </div>

            <div class="footer-left-list">
              <p class="footer-left-list-title">操作攻略</p>
              <p><a :href="links.companyPDF" target="_blank">企业操作手册</a></p>
              <p><a :href="links.userPDF" target="_blank">求职操作手册</a></p>
              <p><a :href="links.service" target="_blank">会员服务指南</a></p>
            </div>

             <div class="footer-left-list">
              <p class="footer-left-list-title">关于我们</p>
              <p><a @click="changeModal">意见建议</a></p>
            </div>
          </div>
          
          <div style="text-align:center;color:#666" class="copyright" ><img class="copyright-logo" :src="require('assets/logo.png')"> <span class="copyright-text">Copyright &copy; <a style="color:#666" href="https://beian.miit.gov.cn/#/Integrated/index"> 2002-2016 WWW.QZRCW.COM All Rights Reserved 浙ICP备05002279号-1</a> </span></div>
        </div>

        <div class="footer-qr">
            <div class="icon-box icon1" :class="qrShow==1&&'active'" @click="()=>{qrShow=1}">
              <div class="qr" v-show="qrShow===1">
                <el-image src="https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=gQGI8TwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAybTJUdUlKMk9lY0QxMDAwMGcwN0IAAgQpiARjAwQAAAAA"></el-image>
                <div class="img-mark" >联系客服</div>
              </div>
            </div>
            <div class="icon-box icon2" :class="qrShow==2&&'active'" @click="()=>{qrShow=2}">
              <div class="qr" v-show="qrShow===2">
                <el-image src="https://qz-1257093263.cos.ap-shanghai.myqcloud.com/fdwx.png"></el-image>
                <div class="img-mark" >衢州人才网 饭弟</div>

              </div>
            </div>
            <div class="icon-box icon3" :class="qrShow==3&&'active'" @click="()=>{qrShow=3}">
              <div class="qr" v-show="qrShow===3">
                <el-image :src="require('../assets/qrcode_for_qzrcw_ggh.jpg')"></el-image>
                <div class="img-mark" >衢州人才网公众号</div>

              </div>
            </div>
            <div class="icon-box icon4" :class="qrShow==4&&'active'" @click="()=>{qrShow=4}">
              <div class="qr" v-show="qrShow===4">
                <el-image src="https://qz-1257093263.cos.ap-shanghai.myqcloud.com/qzrcwweapp.jpg"></el-image>
                <div class="img-mark" >衢州人才网小程序</div>
              </div>
            </div>
              <!-- <div>人才网在线咨询</div>
              <img style="width:100%;hight:100%" src="https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=gQFV8DwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAyT2RPWEp0dWthSmUxMDAwMGcwN3kAAgQC50phAwQAAAAA" alt=""> -->
        </div>
      </div>
    </div>
    <LoginBox style="position:fixed;top:50%;left:50%;translate:-50%,-50%,0" :showLogin="showLogin" @showLoginBox="changeShowLoginbox"/>
    <Suggestion type="dialog" :show="show" @close="suggestionClose"/>
    
  </div>
</template>

<script>
// <p><span class="footer-l">业务咨询：0570 308 6576</span></p>
// <p><span class="footer-l">网站问题咨询：+86 198 5700 5568</span></p>
import LoginBox from "components/loginBox";
import Suggestion from "components/suggestion"
import {links} from 'constants/routerLink';
export default {
  name:"Footer",
  data:()=>({
    showLogin:false,
    qrShow:1,
    show:false,
  }),
  methods:{
    changeShowLoginbox(){
      this.showLogin=!this.showLogin
    },
    suggestionClose(){
      this.changeModal()
    },
    changeModal(){
      this.show=!this.show
    }
  },
  computed:{
    links:()=>links,
    
  },
  watch:{},
  components:{
    LoginBox,
    Suggestion
  }

}
</script>
<style lang="scss">
  @import 'constants/style/public';
  .footer {
    padding: 20px 0;
    background-color: $headBgColor;
    width: 100%;
    border-top: 1px solid $lineColor;
    text-align: left;
    .container{
      font-size: 14px;
      height: 100%;
      .footer-left{
        color: #666;
        .footer-left-links{
          width: 800px;
          display: flex;
          margin-bottom: 50px;
        }
        .footer-left-list{
          display: flex;
          flex-direction: column;
          width:150px;
          a{
            cursor: pointer;
            color:$cardsubTextColor;
            &:hover{
              color: $moreColor;
            }
          }
        }
        .footer-left-list-title{
          font-size: $large;
          font-weight: $weight-medium;
          color: $titleColor;
        }
        .copyright{
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .copyright-logo{
          height: 25px;
          margin-right: 20px;
          
        }
      }
      
    }
    .footer-qr{
        margin-right: 20px;
        display: flex;
        width:200px;
        justify-content: space-between;
        text-align:center;
        position: relative;
        .icon-box{
          width: 32px;
          height: 32px;
          background-size: contain;
          .qr{
            border: 1px solid #efefef;
            // border-radius: 8px;
            width: 200px;
            position: absolute;
            top: 40px;
            left: 50%;
            transform: translate3d(-50%,0,0);
            .img-mark{
              text-align: center;
              padding-bottom: 5px;
            }
          }
          &.icon1{
            background-image: url('https://qz-1257093263.cos.ap-shanghai.myqcloud.com/footer_1.png');
            &:hover,&.active{
              background-image: url('https://qz-1257093263.cos.ap-shanghai.myqcloud.com/footer_1_1.png');
            }
          }
          &.icon2{
            background-image: url('https://qz-1257093263.cos.ap-shanghai.myqcloud.com/footer_2.png');
            &:hover,&.active{
              background-image: url('https://qz-1257093263.cos.ap-shanghai.myqcloud.com/footer_2_1.png');
            }
          }
          &.icon3{
            background-image: url('https://qz-1257093263.cos.ap-shanghai.myqcloud.com/footer_3.png');
            &:hover,&.active{
              background-image: url('https://qz-1257093263.cos.ap-shanghai.myqcloud.com/footer_3_1.png');
            }
          }
          &.icon4{
            background-image: url('https://qz-1257093263.cos.ap-shanghai.myqcloud.com/footer_4.png');
            &:hover,&.active{
              background-image: url('https://qz-1257093263.cos.ap-shanghai.myqcloud.com/footer_4_1.png');
            }
          }
        }
      }
  }
</style>