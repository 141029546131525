export default (...ids) => ({
  // 'test': `/personal/career_fairs/`,
  'getStyle': `/personal/valley/${ids[0]}/get_valley_style/`,   // 获取样式
  // 'getHomeCompany': `/personal/valley/${ids[0]}/get_valley_companies/`,   // 获取主页的公司列表
  // 'getHomeActive': `/personal/valley/${ids[0]}/career_fairs/`,    // 获取主页活动列表
  // 'getHomePositionList': `/public/jobs/`,    // 获取主页的职位列表
  // 'getCompanyDetail': `/public/companies/${ids[1]}/`,   // 获取公司详情
  // 'getCompanyPositionList': `/public/companies/${ids[1]}/jobs/`,    // 获取公司的职位列表
  // 'getCompanyPositionDetail': `/public/companies/${ids[1]}/jobs/${ids[2]}/`,    // 获取公司职位详情
  // 'getActiveDetail': `/personal/career_fairs/${ids[1]}/`,    // 获取活动详情
  // 'getAllCity': `/public/job_file/city_group.json/`,    // 获取所以城市数据
  // 'getCounty': `/public/city_and_county/`,   // 获取区县列表
  // 'getCompanyJobId': `/public/get_company_and_job_info_by_position_number/`,   // 电视获取职位列表

  //获取样式
  // getValleyMessage: `/public/valley/${ids[0]}/valley_message_list/`,

  getValleyMessage: `/public/valley/294/valley_message_list/`,//资讯
  getValleyMessageDetail: `/public/valley/294/valley_message_list/${ids[1]}/`,//资讯详情
  
  getHotJobs: `/public/circles/all_jobs/?circle_id=2`,//职位列表
  getJobDetail: `/personal/jobs/${ids[1]}/`,//职位详情
  
  getActivity: `/personal/career_fairs/`,//活动列表
  getActivityDetail: `/personal/career_fairs/${ids[1]}/`,//活动详情

  getCompanyList: `/public/circles/all_companies/?circle_id=2`,//公司列表
  getCompanyDetail:`/public/companies/${ids[1]}/`,//公司详情
  getCompanyDetailIsToken: `/personal/${ids[2]}/companies/${ids[1]}/`,//登录后获取公司详情 company_id user_id 

  getCompanyJobs:`/public/circles/all_companies/${ids[1]}/jobs/`,//公司的职位

  getCareerFairCompanys:`/public/companies/`,//活动下的公司
  getCareerFairJobs:`/public/jobs/`,//活动下的职位

  getWxLoginQr:`/public/get_miniprogram_qr_code_url/`,
  getOpenId:`/companies/get_login_qr_code_url/?source_web=5`,
  lx:`/companies/login_status/`,

  getWxResume:`/personal/${ids[1]}/resume/${ids[2]}/`,//userid,resumeid
  getWxUserInfo:`/personal/${ids[1]}/`,//userid

  postToApply:`/personal/${ids[1]}/apply/`,//user_id
  postToChannel:`/personal/${ids[1]}/channel_list/`,//user_id
  
  getAdvertise:`/public/circles/2/`,//circle_id

  putResumeData:`/personal/${ids[1]}/resume/${ids[2]}/`,//userid,resumeid,

  postUploadFile:`/public/sz_upload/image/`,

  postAvatarImage:`/personal/${ids[1]}/upload_personal_avatar/`,//userid

  getUserDeliverRecord:`/personal/${ids[1]}/applied_jobs/`,//userid

  postSuggestion:`/personal/user_suggestion_box/`,

  getExamination: `/personal/career_fairs/`,//活动列表
  
  getExaminationDetail: `/personal/career_fairs/${ids[1]}/`,//活动详情
  
  getExaminationJobs:`/public/jobs/`,

  postExaminationApply:`/personal/${ids[1]}/apply_exam/`, //报名考试 userId
  
  getPublicResumeList:`/circles/2/public_resumes/`,

  postStarRete:`/personal/${ids[1]}/user_comment_company/${ids[2]}/`, // 星评 userId,companyId

  putRefresh:`/users/${ids[1]}/modify_resume_last_refresh_time/${ids[2]}/`, // 刷新简历 userId,resumeId
  
  getSavePageView:`/public/valley/294/valley_message_look_up/${ids[1]}/`, // 记录浏览 :valley_message_id
  getActivityCompanyJobList:`/companies/qzrcw/activity_company_job_list/`, // 记录浏览 :valley_message_id
  getActivityCompanyList:`/companies/qzrcw/activity_company_list/`, // 记录浏览 :valley_message_id
  getCareerFairCount:`/career_fairs/qzrcw/career_fair_count/`, // 记录浏览 :valley_message_id

  getSixIndustryCompanies:`/public/big_six_industry_company_list/`, //六大产业公司列表
  getSixIndustryJobs:`/public/big_six_industry_company_job_list`, //六大产业公司列表
  
  // get
  //business_id: 136523
  // business_type: 1
  // message_content: "我已投递您的职位，期待与您联系"

  
  // getCompanyList: `/public/companies/`,
  // getCompanyJobs:`/public/jobs/?company_id=${ids[1]}`,
  // 获取活动
  // getActivityData: `/public/valley/${ids[0]}/valley_message_list/`

})

//衢州人才网 circle_id：2 