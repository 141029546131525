<template>
  <div class="header">
    <div class="page-container">
      <div class="container">
        <div><img style="height: 36px;object-fit:cover" :src="require('assets/logo.png')" /></div>
        <div class="right-link-list">
          <div class="text-item" v-for="(item) in headerList" :key="item.text">
            <router-link tag="div" :to="item.link" :class=" 'list-type '+ (curItem===item.text?'active':'')" :href="item.link">{{item.text}}</router-link>
          </div>

         <div class="text-item">
            <a target="_blank" class="login-btn company" :href="ats">
              <span >企业登录</span>
            </a>
         </div>

          <div>
            <div v-if="!!official_open_id" :class="'login-user ' +(curItem==='user'?'user-active':'')">
              <el-menu mode="horizontal" @select="handleSelect">
                <el-submenu index="1">
                  <template slot="title"><el-avatar class="avatar" :size="32" :src="avatar"></el-avatar></template>
                  <el-menu-item index="to">我的简历</el-menu-item>
                  <!-- <el-menu-item index="record">查看记录</el-menu-item> -->
                  <el-menu-item index="out">退出</el-menu-item>
                </el-submenu>
              </el-menu>
              <!-- <span>匿名用户</span> -->
            </div>
            <div v-else class=" login-btn user login-box-container" @click="showLogin=true">
              <span class="login-text">个人登录</span>
            </div>
          </div>
          
          
          <LoginBox style="position:fixed;top:50%;left:50%;translate:-50%,-50%,0" :showLogin="showLogin" @showLoginBox="changeShowLoginbox"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {headerList,hrefs} from "constants/publicData"
import LoginBox from 'components/loginBox'
import {mapState,mapMutations,mapActions} from "vuex"
export default {
  name:"Header",
  data(){
    return{
      showLogin:false,
      curItem:headerList[0].text,
      avatar:"https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    }
  },
   computed:{
    ...mapState({
      official_open_id:state=>state.official_open_id,
      userId:state=>state.user_id,
      userInfo:state=>state.userInfo
    }),
    headerList:()=>headerList,
    ats:()=>hrefs.ats,
  },
  created(){
    if(this.userId){
      this.getUserInfo(this.userId)
    }
  },
  methods:{
    ...mapMutations(['setToken','setUserId','setOpenId']),
    ...mapActions(['getUserInfo']),
    changeShowLoginbox(type){
      this.showLogin=type
    },
    handleSelect(key){
      if(key==='out'){
        // this.avatar = 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
        localStorage.removeItem('PublicQrData')
        this.setToken(null)
        this.setUserId(null)
        this.setOpenId(null)
        this.$router.push('/')
      }
      if(key=='record'){
        this.$router.push("/record")
      }
      if(key=='to'){
        this.$router.push("/user")
      }
    },
    toUser(){
      this.$router.push("/user")
    },
    
  },
 
  watch:{
    $route(route){
      let [items] = headerList.filter(element => route.matched.some(item=>(item.meta.key===element.link||item.path===element.link)))
      let item = items?items.text:null
      if(!item && route.matched.some(item=>(item.path=='/user'))){
        item = 'user'
      }
      this.curItem = item
    },
    userId(user_id,prev){
      console.log(user_id,prev);
      if(prev!==user_id&&user_id){
          this.getUserInfo(user_id)
      }
    },
    userInfo(now,prev){
      if(prev!==now&&now.person_avatar){
        // console.log(now.person_avatar);
        this.avatar = now.person_avatar
      }
    },
  },
  components:{
    LoginBox
  }

}
</script>
<style lang="scss">
  @import 'constants/style/public';
  .header{
    font-size: 16px;
    background-color: $headBgColor;
    width: 100%;
    height: 100%;
    box-shadow: 0px 3px 6px $shadowColor;
    .el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
      border: 0;
    }
    .container{
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 15px;
      .text-item{
        display: flex;
        align-items: center;
        padding-bottom: 2px;
      }
      
    }
    .right-link-list{
      display: flex;
      justify-content: space-around;
    }
    .list-type{
      cursor: pointer;
      padding: 3px 28px 0;
      color: $titleColor;
      opacity: 0.4;
      vertical-align: top;
      &:hover {
        color: $headerSelect;
        opacity: 0.6;
      }
      &.active{
        opacity: 1;
        font-weight: bolder;
        // 
        color: $headerSelect;
        // 
      }
      &.vip{
        opacity: 1;
        color: $headerVipColor;
      }
    }

    .login-btn{
      color: var(--defaultColor,#FFF);
      height: 26px;
      font-size: var(--large,16px);
      border-radius: 999px;
      padding: 0 15px;
      margin-left: 15px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &.company{
        background-color: $primaryColor;
      }
      &.user{
        background-color: #3B3BEE;
      }
    }
    .login-user{
        display: flex;
        align-items: center;
        margin-left: 15px;
        &.user-active{
          border-color:$titleColor ;
        }
        .avatar{
          cursor: pointer;
        }
        span{
          margin-right: 10px;
        }
      }
      
    .el-menu.el-menu--horizontal{
      border-bottom: 0;
    }
    

    // .login-box-container{
    //   font-size: 14px;
    //   cursor: pointer;
    //   opacity: 0.4;
    //   margin-left: 28px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   border: 1px solid $titleColor;
    //   height: 26px;
    //   padding: 0 12px;
    //   border-radius: 999px;
    //   vertical-align: top;
    //   transition: opacity .2s;
    //   .login-text{
    //     color: $titleColor;
    //     &:hover{
    //       color:$titleColor
    //     }
    //   }
    //   &:hover {
    //     opacity: 1;
    //   }
    // }
  }
  
</style>