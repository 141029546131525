import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dayjs from 'dayjs'
import Print from 'vue-print-nb'
import { axiosRequest } from 'constants/api.js'

import * as apiData from './constants/apiData'
import * as publicData from './constants/publicData'
import * as routerLink from './constants/routerLink'
import * as utils from './constants/utils'


import './constants/element'

Vue.use(Print)

Vue.config.productionTip = false

Vue.prototype.$dayjs = dayjs

//绑定ajax请求,注：请使用$axiosRequest
Vue.prototype.axiosRequest = axiosRequest
Vue.prototype.$axiosRequest = axiosRequest

//为了方便操作将通用数据绑定和工具方法到Vue对象上
Vue.prototype.$usePublicData = publicData
Vue.prototype.$useRouterLinks = routerLink
Vue.prototype.$useAjaxLinks = apiData
Vue.prototype.$useUtils = utils


Vue.prototype.$getWeappQrCode = async function getWeappQrCode(openId) {
  try{
    let res = await this.axiosRequest({
      name: "scan",
      params: { scene: openId, source_web: 5 },
    })
    console.log("qr", res.data);
    return res.data.miniprogram_qr_code_url
  }catch(err){
    console.log(err);
  }



// Vue.prototype.$getWeappQrCode = async function getWeappQrCode(openId) {
//   try{
//     let res = await this.axiosRequest({
//       name: "scan",
//       params: { scene: openId, source_web: 5 },
//     })
//     console.log("qr", res.data);
//     return res.data.miniprogram_qr_code_url
//   }catch(err){
//     console.log(err);
//   }

  // this.axiosRequest({
  //   name: "scan",
  //   params: { scene: openId, source_web: 5 },
  // }).then(()=>{

  // }).catch(()=>{
  //   return Pro
  // })
}

router.beforeEach((to, from, next) => {
  if (to.path === '/error') {
    next()
  }
  if (store.state.apiStyle) {
    document.title = store.state.apiStyle.web_manager;
    let link = document.createElement("link");
    link.rel = "shortcut icon";
    link.href = store.state.apiStyle.logo;
    document.getElementsByTagName("head")[0].appendChild(link);
    next()
  } else {
    // axiosRequest({
    //   name: 'getStyle'
    // }).then(res => {
    //   store.commit('changeApiStyle', res.data)
    //   document.title = res.data.web_manager;
    //   let link = document.createElement("link");
    //   link.rel = "shortcut icon";
    //   link.href = res.data.logo;
    //   document.getElementsByTagName("head")[0].appendChild(link);
    //   next()
    // }).catch(err => {
    //   console.log(err)
    //   next()
    // })
    next()
  }
})

let judge = true

window.onresize = function (e) {
  if (judge) {
    judge = false
    setTimeout(() => {
      store.commit('changeWindowWidth', e.currentTarget.innerWidth)
      judge = true
    }, 100);
  } else {
    return
  }
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
