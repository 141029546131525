import axios from 'axios'
import Vue from 'vue';
import router from '@/router'
import {
  Message,
  // Loading
} from 'element-ui';
import apiData from './apiData'
import { valley_id } from './publicData'
import {getStore} from "../store"

const HOST = (function(){
  if(process.env.NODE_ENV==="development"){
    /* 开发 */
    // return "https://api-test.jialeijob.com"
    // return 'http://120.26.42.162:8000'
    // return "http://192.168.0.14:7000"
    // return "http://192.168.0.14:8000"
    // return "http://192.168.0.15:7000"
    // return "http://192.168.31.55:8001"
    // return 'https://api-test.qzrcw.com'
  }
  /* 线上 */
  return 'https://api-test.qzrcw.com'
})()

const baseUrl = HOST+'/api'
const service = axios.create({
  baseURL: baseUrl,
  timeout: 60000
})

// let loading;

// 请求拦截
service.interceptors.request.use(config => {
  let {headers,header} = config
  config.headers = Object.assign(headers,header)
  delete config.header
  delete config.ids
  // console.log('config',config);

  return config
})

// 响应拦截
service.interceptors.response.use(response => {
  return response
},function (err){
  console.log('响应拦截器',err.response);
  

  if (router.currentRoute.path !== '/error') {
    if(err.response.status===400){
      Message.error(err.response.data.reason)
    }else if(err.response.status===403){
      Message.error("请重新登陆")
      let store = getStore()
      localStorage.removeItem('PublicQrData');    
      store.commit('setToken',null);
      store.commit('setUserId',null);
      store.commit('setOpenId',null);
      
      // window.location.replace("/home");
      
    }else if(err.response.status===404) {
      console.log("路径错误") 
    }else {
      let app = new Vue()
      app.$notify({
        title: '网络错误',
        message: `网络错误，您可尝试重新登录 或联系网站运营，饭弟 198 5700 5568`
      });
    }
  }
  return Promise.reject(err)
})

/**
 * config: 
 *  name: 接口url----''
 *  method: 请求方式----''
 *  data: 非get请求时的参数----{}
 *  params: get请求时的query参数----{}
 *  ids: url携带参数----[]
    token:  是否携带token
*/
export function axiosRequest (config) {
  let url = ''
  let header = {
    "Content-Type": "application/json",
  }
  if(config.token){
    delete config.token
    header.jwt = localStorage.getItem("token")
  }
  let ids = [valley_id]
  ids = ids.concat(config.ids)
  // console.log(ids);
  if (config.name) {
    url = apiData(...ids)[config.name]
    config.url = url
    // console.log("name:",config.name,",config.url:",url,);
    if (!url) {
      return Promise.reject('无此请求')
    }
  } else {
    return Promise.reject('无此请求')
  }
  config.header = header
  // console.log('config',config);
  return service(config)
}