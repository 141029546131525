export const ats = 'http://ats.qzrcw.com/login'
export const cfts = 'https://admin.qzrcw.com/login'

export const toJobs = `/jobs/`
export const toJobDetail = `/jobs/detail/`

export const toCompanys = `/company/`
export const toCompanyDetail = `/company/detail/`

export const toInformation = `/information/`
export const toInformationDetail = `/information/detail/`

export const toActivity = `/activity/`
export const toActivityDetail = `/activity/detail/`

export const toExamination = `/examination/`
export const toExaminationDetail = `/examination/detail/`
export const toSpecialCf = '/specialCf'
export const links = {
  ats:ats,
  activity:'/activity',
  information_0:'/information/?id=0',
  information_1:'/information/?id=1',
  companyPDF:'https://miniapp-1257093263.cos.ap-nanjing.myqcloud.com/qz/%E8%A1%A2%E5%B7%9E%E4%BA%BA%E6%89%8D%E7%BD%91%E4%BC%9A%E5%91%98%E5%8D%95%E4%BD%8D%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C.pdf',
  userPDF:'https://internal-1257093263.cos.ap-shanghai.myqcloud.com/qz_qzz%E8%A1%A2%E5%B7%9E%E4%BA%BA%E6%89%8D%E7%BD%91%E6%B1%82%E8%81%8C%E8%80%85%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%202021-10-22.pdf',
  service:'/information/detail/21'
}