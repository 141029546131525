
import dayjs from "dayjs";


export function htmlUnEscape(str) {
  return str.replace(/&lt;|&gt;|&quot;|&amp;/g, (match) => {
    switch (match) {
      case '&lt;':
        return '<';
      case '&gt;':
        return '>';
      case '&quot;':
        return '"';
      case '&amp;':
        return '&';
    }
  });
}
export function selectOriginNode(node,data){
  if(!node){
    return null
  }
  let l = node 
  // console.log(l);
  l = l.split('-')
  if(Array.isArray(l)&&l.length>1){
    return l
  }else{
    let value = processNodes(node,data)
    // console.log(value);
    if(value) value = value.split('-')
    return value
  }
}

function processNodes(node,data){
  for(let i=0; i<data.length;i++){
    
    if(data[i].children && data[i].children.length){
      // console.log('nodeName',node,data[i].children);
      let value = processNodes(node,data[i].children)
      if(value)return `${data[i].value}-${value}`
      else continue;

    }else{
      if(node===data[i].value) return data[i].value
      else continue;
    }
  }
}

export function processTimeGroup (start,end,interval="~",format="YYYY/MM/DD"){
  if(start&&end&&dayjs(start).diff(end)<0){
    return dayjs(start).format(format)+interval+dayjs(end).format(format)
  }else if(start&&!end){
    return dayjs(start).format(format)+interval+'至今'
  }else{
    return ""
  }
}
