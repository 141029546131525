import Vue from 'vue'
import Vuex from 'vuex'
import dayjs from 'dayjs'
import { axiosRequest } from 'constants/api.js'

Vue.use(Vuex)

  const windowWidth = window.innerWidth
 const STORE = new Vuex.Store({
  state: {
    windowWidth: windowWidth,
    apiStyle: null,
    token:localStorage.getItem('token')||null,
    user_id:localStorage.getItem('userId')||null,
    official_open_id:localStorage.getItem('openId')||null,
    userInfo:{},
    cs:0
  },
  mutations: {
    changeWindowWidth (state, data) {
      state.windowWidth = data
    },
    changeApiStyle (state, data) {
      state.apiStyle = data
    },
    setToken(state, token){
      if(token===null){
        localStorage.removeItem('token')
      }else{
        localStorage.setItem("token",token)
        localStorage.removeItem("PublicQrData")
      }
      state.token = token
    },
    setUserId(state,user_id){
      if(user_id===null){
        localStorage.removeItem('userId')
      }else{
        localStorage.setItem("userId",user_id)
      }
      state.user_id = user_id
    },
    setOpenId(state,official_open_id){
      if(official_open_id===null){
        localStorage.removeItem('openId')
      }else{
        localStorage.setItem("openId",official_open_id)
      }
      state.official_open_id = official_open_id
    },
    setUserInfo(state,data){
      state.userInfo = data
    },
    setPublicQrId(state,qr_code_url_id){
      state.qr_code_url_id = qr_code_url_id
      console.log(qr_code_url_id);
      localStorage.setItem('PublicQrData',JSON.stringify({
        qr_code_url_id,
        outTime:dayjs().hour(24)
      }))
    },
    cs(state,data){
      state.cs = data
    }
  },
  actions: {
    getUserInfo({commit},user_id){
      axiosRequest({
        name: "getWxUserInfo",
        ids:[user_id],
        token:true,
      }).then(res=>{
        if(res.status===200){
          commit('setUserInfo',res.data)
        }
      }).catch(err=>{
        console.log(err);
        return Promise.reject(err)
      })
    },
  },
  modules: {
  }
})

export const getStore = ()=>{
  return STORE
}

export default STORE
