
<template>
  <div class="compoent-login-box">
    <el-dialog
      title="微信扫码"
      :visible.sync="showLogin"
      width="360px"
      :before-close="close"
      center
      append-to-body  
    >
      <div style="margin-bottom:15px;text-align:center">
        <div style="margin-bottom:8px">关注衢州人才网公众号后登录</div>
      </div>
      <div class="login-img-box">
        
        <div style="width:200px;height:200px;position:relative">
          <el-image
          style="width:200px;height:200px;overflow:hidden;"
          :style="outQr?'filter:blur(5px)':''"
          :src="openQrData.official_account_login_qr_code_url"
         >
          <div slot="error">
          </div>
          </el-image>
          <div class="zhezhao" @click="getOpenid" :style="outQr?'display:flex;border:2px solid #eee;border-radius: 8px;':'display:none'">
            <div class="zhezhao-wapper">
              <span class="icon el-icon-refresh-right"></span>
              <span>点击刷新</span>
            </div>
          </div>
        </div>
         
      </div>
      <div style="margin-top:10px;text-align:center;line-height:1.5">首次登录后，请在 ‘我的简历’ 页面同步您在衢州人才网小程序的简历信息</div>

      <span slot="footer">
        <el-button class="login-box-back" @click.stop="close" >返 回</el-button>
        <!-- <el-button type="primary" @click="()=>{$emit('showLoginBox',false)}">确 定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapMutations,mapState } from 'vuex'
export default {
  name:"LoginBox",
  props:["showLogin"],
  data:()=>({
    lxnums:0,
    openQrData:{},
    outQr:true,
    openIdTimer:null
  }),
  methods:{
    ...mapMutations(["setOpenId", "setToken", "setUserId","setUserInfo","setPublicQrId"]),
    close(){
      clearTimeout(this.openIdTimer)
      this.$emit('showLoginBox',false)
    },
    
    getOpenid() {
      this.$axiosRequest({
        name: "getOpenId",
      })
        .then((res) => {
          if (res.status === 200) {
            this.openQrData = res.data;
            console.log("openQrData", res.data);
            this.setPublicQrId(res.data.qr_code_url_id)
            this.defaultlxNmus(this.getLx);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    defaultlxNmus(fn) {
      this.lxnums = 24;
      this.outQr = false
      if (fn) {
        fn();
      }
    },
    getLx() {
      this.$axiosRequest({
        name: "lx",
        params: {
          qr_code_url_id: this.openQrData.qr_code_url_id,
        },
      })
        .then((res) => {
          this.lxnums -= 1;
          if (res.status === 200) {
            // console.log(res);
            if (res.data.focus_status !== false && res.data.official_open_id) {
              this.setOpenId(res.data.official_open_id);
              if (res.data.token) {
                this.setToken(res.data.token); 
              }
              if(res.data.user_id){
                this.setUserId(res.data.user_id);
              }
              if(!(res.data.token&&res.data.user_id)){
                this.$router.push('/user')
              }
              this.close()
            } else {
              this.openIdTimer = setTimeout(() => {
                if (this.lxnums > 0) this.getLx();
                else this.outQr = true
              }, 2000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUserInfo(user_id){
      this.$axiosRequest({
        name: "getWxUserInfo",
        ids:[user_id],
        token:true,
      }).then(res=>{
        if(res.status===200){
          console.log(res);
          this.setUserInfo(res.data)
        }
      }).catch(err=>{
        console.log(err);
      })
    }
  },
  computed:{
    ...mapState({
      token:state=>state.token,
      userId:state=>state.user_id
    })
  },
  watch:{
    showLogin(val,prev){
      if(prev!==val&&val){
        this.getOpenid()
      }
    },
    token(now,prev){
      if(now!==prev&&now){
        this.getUserInfo(this.userId)
      }
    }
  },

}
</script>
<style lang="scss">
@import "constants/style/public";
  .login-img-box{
    display: flex;
    justify-content: center;
    align-content: center;
    .zhezhao{
      position: absolute;
      top:0;
      left: 0;
      width:200px;
      height:200px;
      justify-content: center;
      align-items: center;
      .zhezhao-wapper{
        cursor: pointer;
        display: flex;
        align-items: center;
        padding:8px;
        border-radius: 8px;
        background-color: #fff;
      }
      .icon{
        font-size: 20px;
        margin-right: 3px;
        color: $moreColor;
      }
      
    }
   
  }
   .login-box-back.el-button:hover{
      background-color: rgba($color: $moreColor, $alpha: 0.1);
      color:$moreColor
    }
</style>

