<template>
  <div class="suggestion"> 
    <div v-if="type==='card'">
      <div class="card-title">意见反馈</div>
        <div class="input-item">
          <span>姓名：</span>
          <el-input
            class="line-input"
            placeholder="请输入您的姓名"
            v-model="name">
          </el-input>
        </div>
        <div class="input-item">
          <span>手机号码：</span>
          <el-input
            class="line-input"
            placeholder="请输入您的手机号码"
            v-model="phone_number">
          </el-input>
        </div>
        <div class="input-item">
          <span>意见与反馈：</span>
          <el-input
            class="line-textarea"
            type="textarea"
            placeholder="请提出您的意见与反馈"
            v-model="textarea">
          </el-input>
        </div>
      <div class="card-btn">
        <el-button round class="btn" size="small" @click="submit" :loading="loading">提 交</el-button>
      </div>
    </div>
    <div v-if="type==='dialog'">
      <el-dialog
        title="意见与反馈"
        :visible.sync="show"
        width="30%"
        center>
        <div>
         <div class="input-item">
          <span>姓名：</span>
          <el-input
            class="line-input"
            placeholder="请输入您的姓名"
            v-model="name">
          </el-input>
        </div>
        <div class="input-item">
          <span>手机号码：</span>
          <el-input
            class="line-input"
            placeholder="请输入您的手机号码"
            v-model="phone_number">
          </el-input>
        </div>
        <div class="input-item">
          <span>意见与反馈：</span>
          <el-input
            class="line-textarea"
            type="textarea"
            placeholder="请提出您的意见与反馈"
            v-model="textarea">
          </el-input>
        </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" @click="submit" :loading="loading">提 交</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  name:"suggestion",
  props:{
    type:{
      form:{},
      type:[String,null,undefined],
      default:"card"
    },
    show:{
      type:Boolean,
      value:false
    },
  },
  data:()=>({
    textarea:"",
    name:"",
    phone_number:"",
    loading:false
  }),
  methods:{
    close(){
      this.$emit("close")
    },
    async submit(){
      this.loading=true
      const {name,textarea,phone_number} = this
      if(!name||!textarea||!phone_number){
          this.$message({
            message:"请填写反馈信息",
            type:"warning"
          });
         return
      }
      try{
        let res = await this.$axiosRequest({method:"POST", name: "postSuggestion",data:{name,phone_number,json_data:{content:textarea}}})
        if (res.status === 200||res.status === 201) {
          this.$emit("close")
          this.$message({
            message:"提交成功",
            type:"success"
          });
        }else{
          this.$message.error('请重试');
        }
        this.loading=false
      }catch(err){
        this.loading=false
        this.$message.error('请重试');
      }
    },
  },
  computed:{
  },
  components:{
  },
  watch:{},

}
</script>
<style lang="scss">
// @import "constants/style/public";
  .suggestion{
    padding: 14px;
    text-align: left;
    width: 100%;
    height: 100%;
    .card-btn{
      margin-top: 16px;
      text-align: center;
      .btn{
        width: 160px;
      }
      .el-button{
        background-color: var(--moreColor,#666);
        border:1px solid var(--moreColor,#666);
        color: #fff;
        &:active{
          opacity: 0.8;
        }
      }
    }
    .input-item{
      display: flex;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      span{
        text-align: right;
        width: 72px;
        flex: none;
        justify-self: flex-end;
      }
      .line-input,.line-textarea{
        font-size: 12px;
        width: 196px;
        height: 25px;
        margin-bottom: 4px;
        .el-input__inner{
          height: 100%;
        }
      }
      .line-textarea{
        height: auto;
        margin-bottom: 0;
      }
    }
    .card-title{
      font-size: var(--large,16px);
      color: var(--titleColor);
      margin-bottom: 14px;
    }
  }
  
</style>