<template>
  <div id="app">
    <!-- <div class="ls" v-show="!show" >
      <img style="width:100%;height:100%;object-fit: cover;" :src="require('assets/bbbbbb.jpg')" alt="">
      <input class="ipt" type="text" v-model="iptValue" @keyup.enter="checkInput">
    </div> -->
    <el-container>
      <el-header>
        <Header />
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <el-footer style="height:inherit">
        <Footer />
      </el-footer>
    </el-container>

    <!-- <el-dialog title="公告" :visible.sync="open" width="500px" center append-to-body>
      <div>
        <p>各位衢州人才网尊敬的用户：</p>
        <p>您好！随着平台企业及求职者数量稳步上升，为进一步提升用户体验，衢州人才网将对机房的整体网络和硬件进行升级。
        <strong>从今日起：每天晚上9点至第二天上午7点，为人才网系统升级时间；</strong>
        届时人才网将暂时关闭，期间给您带来的不便，敬请谅解，感谢您的理解与支持！</p>

        <p style="text-align: right;">衢州人才网</p>
        <p style="text-align: right;">2023年6月11日</p>
      </div>
    </el-dialog> -->

  </div>
</template>

<script>
import Footer from "components/footer";
import Header from "components/header";
export default {
  data() {
    return {
      open: false
      // show:false,
      // iptValue:"",
      // loginPassword:"jialeikeji"
    }
  },
  // created(){
  //   this.show = sessionStorage.getItem("checkLogin")||false
  // },
  methods: {
    // checkInput(){
    //   console.log(this.iptValue);
    //   console.log(this.loginPassword);
    //   // console.log("登录",this.iptValue==this.loginPassword);
    //   if(this.iptValue==this.loginPassword){
    //     sessionStorage.setItem("checkLogin",true)
    //     this.show=true
    //   }
    // }
  },
  components: {
    Footer,
    Header,
  },

};
</script>

<style lang="scss">
@import "constants/style/root.scss";



.gray-filter {
  filter: grayscale(1);
}

// .ls{
//   position: fixed;
//   z-index: 999999999;
//   top: 0;
//   left: 0;
//   margin: 0;
//   padding: 0;
//   width: 100%;
//   height: 100vh;
//   display: block;
//   .ipt{
//     position:absolute;
//     bottom: 20px;
//     right: 0;
//   }
// }
body {
  margin: 0;
  padding: 0;
}

div {
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
}

.el-container,
.el-header,
.el-main,
.el-footer {
  min-width: 1200px;
  padding: 0 !important;
}

.el-header {
  position: relative;
  z-index: 99;
}

.el-main {
  overflow: visible !important;
  // background: url("assets/main_bg.jpg") no-repeat;
  background: #F6F7F8 no-repeat;
  background-size: 100% 100%;
  background-position: center bottom;
  background-attachment: fixed
}

.el-container {
  min-height: 100vh;
}
</style>
